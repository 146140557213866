<template>
  <div id="app">
    <router-view v-if="roles.length === 0 || ( roles.includes('Admin') || roles.includes('Editor'))" />
    <guest-dashboard v-if="roles.length > 0 && ( !roles.includes('Admin') && !roles.includes('Editor'))" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GuestDashboard from './dashboard/guest';

export default {
  name: 'App',
  components: { GuestDashboard },
  data() {
    return {
      currentRole: 'adminDashboard',
    };
  },
  computed: {
    ...mapGetters([
      'roles',
    ]),
  },
};
</script>
