/* eslint-disable quotes */
/** On peut décomposer les routes par module**/
import Layout from "@/layout";

const ArticleRoutes = {
  path: "/articles",
  component: Layout,
  redirect: "/articles/index",
  name: "Article",
  // alwaysShow: true,
  meta: {
    title: "articles",
    icon: "el-icon-tickets",
    permissions: ["manage article"],
  },
  children: [
    {
      path: "create",
      component: () =>
        import(
          /* webpackChunkName: "create_article" */ "@/views/articles/Create"
        ),
      name: "CreateArticle",
      meta: {
        title: "createArticle",
        icon: "edit",
        permissions: ["create article"],
      },
      hidden: true,
    },
    {
      path: "edit/:id(\\d+)",
      component: () =>
        import(/* webpackChunkName: "edit_article" */ "@/views/articles/Edit"),
      name: "EditArticle",
      meta: {
        title: "editArticle",
        noCache: true,
        permissions: ["edit article"],
      },
      hidden: true,
    },
    {
      path: "index",
      component: () =>
        import(/* webpackChunkName: "list_articles" */ "@/views/articles/List"),
      name: "ArticleList",
      meta: {
        title: "articles",
        icon: "el-icon-tickets",
        permissions: ["list article"],
      },
    },
    {
      path: "categories",
      component: () =>
        import(
          /* webpackChunkName: "categories_tags" */ "@/views/articles/Categories_tags"
        ),
      name: "Categories",
      meta: {
        title: "categoriestags",
        icon: "el-icon-folder",
        permissions: ["manage article"],
      },
    },
    {
      path: "comments",
      component: () =>
        import(/* webpackChunkName: "comments" */ "@/views/articles/Comments"),
      name: "Comments",
      meta: {
        title: "comments",
        icon: "el-icon-s-comment",
        permissions: ["manage comment"],
      },
    },
  ],
};

export default ArticleRoutes;
